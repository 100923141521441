.cardContent{
    text-align: center;
    display: flex;
    flex-direction: column;
}
.content{
    width: 70%;
 flex-wrap: wrap;
    align-self: center;
}
 
h1{
    font-size:50px;
}
.header{
    padding-top: 30px;
}

@media only screen and (max-width: 600px) {
    .content{
        width: 90%;
     flex-wrap: wrap;
        align-self: center;
    }
  }