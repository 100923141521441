.quiz-layout{
    padding: 0 20%;
  }

  .timeshow{
    position: fixed;
    padding: 10px;
  background-color: rgb(102, 255, 0);
  border-radius: 5px;
  bottom: 5px;
  right: 5px;
  }

  @media only screen and (max-width: 600px) {
    .quiz-layout{
        padding: 0 0;
      }
  }